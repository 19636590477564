<template>
  <div>
    <v-container fluid>
      <v-card height="400" color="transparent" flat>
        <v-card-title class="text-h2"> Erreur 403 </v-card-title>
        <v-card-subtitle class="text-h6"> Accès refusé </v-card-subtitle>
        <v-card-text>
          Vous n'avez pas les autorisations nécessaire pour accèder à cet élément !
        </v-card-text>
        <v-btn outlined to="/" class="mt-1"> Retour Accueil </v-btn>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Error403",
};
</script>